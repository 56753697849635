import '../css/app.css';
import '../css/animate.min.css';
import '../css/all.min.css';
import '../css/fontawesome-all.min.css';
import '../css/fonts.css';
import '../css/boxes.css';
import '../css/plyr.css';
import '../css/solar.scss';

import Plyr from 'plyr';
const player = new Plyr('#player', {
  autoplay: true,
  debug: true,
  volume: 0.25,
  muted: false,
  loop: { active: true },
  clickToPlay: true,
  invertTime: false,
  displayDuration: true,
  invertTime: false,
  speed: { selected: 1, options: [0.5, 1, 1.5, 2] },
  controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'airplay'],
  settings: ['captions', 'speed'],
});

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import './bootstrap';

import './vendor/pixi';
import './vendor/boxes';

import.meta.glob([
  '../img/**',
  '../fonts/**',
]);

/**
 * Import de jQuery dans chaque fichier JS, y compris dans le main
 */
import $ from "jquery";

/**
 * KeyChain code
 */
var masterKey = [65, 68, 96];
var keyCodes = [];
$(window).keyup(function (e) {
    var code = e.keyCode ? e.keyCode : e.which;
    keyCodes.push(code);
    if (JSON.stringify(keyCodes) === JSON.stringify(masterKey)) {
        window.location.href = '/desk';
    }
    keyCodes = keyCodes.slice(-masterKey.length + 1);
});
