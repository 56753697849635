const boxes = document.querySelectorAll(".box");

boxes.forEach(
  (dabba) =>
    (dabba.style.borderRadius = Array.from(
      { length: 8 },
      () => `${Math.floor(Math.random() * 51) + 50}%`
    )
      .join(" ")
      .replace(
        /(.*) (.*) (.*) (.*) (.*) (.*) (.*) (.*)/,
        "$1 $2 $3 $4 / $5 $6 $7 $8"
      ))
);

var audioContext;
var analyser;
var bufferLength;
var dataArray;

document.body.onclick = () => {
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
  analyser = audioContext.createAnalyser();
  analyser.fftSize = 32;
  bufferLength = analyser.frequencyBinCount;
  dataArray = new Uint8Array(bufferLength);

  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(function (stream) {
      audioContext.createMediaStreamSource(stream).connect(analyser);
      measureLoudness();
    })
    .catch(function (err) {
      console.error("Error accessing microphone:", err);
    });
};

function measureLoudness() {
  setInterval(() => {
    analyser.getByteTimeDomainData(dataArray);
    let sum = 0;
    for (let i = 0; i < bufferLength; i++) {
      sum += Math.abs(dataArray[i] - 128);
    }
    const amplitude = sum / (bufferLength * 6);

    boxes.forEach((dabba) => {
      if (amplitude > 0.069) {
        dabba.style.translate = `${
          (Math.random() > 0.5 ? -1 : 1) * amplitude * 2
        }vw ${(Math.random() > 0.5 ? -1 : 1) * amplitude * 2}vw`;
        dabba.style.scale = 1 + amplitude / 8;
      }
    });
  }, 99);
}
